import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Table,
  Button,
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Chip,
  Divider,
  Tooltip,
  IconButton,
  TablePagination,
} from "@mui/material";

import GroupIcon from "@mui/icons-material/Group";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";
import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import HelpIcon from "@mui/icons-material/Help";
import Analytics from "./Analytics";
import { BASE_URL } from "./../../global";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import WorkReport from "../dashboard/workReport";
import PaymentReport from "../dashboard/paymentReport";
import StdInOut from "../dashboard/stdInOut";
import Revenue from "../dashboard/revenue";
import Dashboard_background from "../image/dashboard.jpg";
import { dashboardGuide } from "./guide";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

const CustomCard = ({ icon, iconColor, title, count, link }) => {
  const IconComponent = icon; // Dynamically set the icon component

  return (
    <Link to={link} style={{ textDecoration: "none" }}>
      <Card
        className="cardContent"
        sx={{
          m: 2,
          p: 1,
          display: "flex",
          border: 1,
          borderColor: "#283593",
        }}
        style={{ height: "125px" }}
      >
        <IconComponent
          sx={{ color: iconColor || "#283593" }}
          style={{ fontSize: "100px" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography>{title}</Typography>
            <Typography
              component="div"
              variant="h4"
              sx={{
                fontWeight: "bold",
                color: iconColor,
              }}
            >
              {count}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </Link>
  );
};
var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "customerName",
    label: "Client",
    align: "left",
    minWidth: 40,
  },
  {
    id: "certificateNumber",
    label: "Certificate Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "ULRNo",
    label: "ULR Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "instrumentName",
    label: "Instrument Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "range",
    label: "Range",
    minWidth: 40,
    align: "left",
  },
  {
    id: "lc",
    label: "LC",
    minWidth: 40,
    align: "left",
  },
  {
    id: "idNo",
    label: "DUC ID",
    minWidth: 40,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "make",
    label: "Make",
    minWidth: 40,
    align: "left",
  },
  {
    id: "model",
    label: "Model",
    minWidth: 40,
    align: "left",
  },
  {
    id: "dateOfCalibration",
    label: "Date of Calibration",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "dueDate",
    label: "Due Date",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

export default function Dashboard() {
  const dashboardGuides = dashboardGuide();
  const [finalData, setFinalData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [certificate, setCertificate] = useState([]);
  const [certReadings, setCertReadings] = React.useState([]);
  const [clientCount, setClientCount] = React.useState("");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [certificateCount, setCertificateCount] = React.useState("");
  const [userType, setUserType] = React.useState(localStorage.getItem("type"));
  const [counts, setCounts] = useState({
    todaysClientsCount: 0,
    todaysSrfsCount: 0,
    todaysCertificatesCount: 0,
    todaysRenewalsCount: 0,
    overAllActiveIncCount: 0,
    overAllRenewalCount: 0,
    overAllClientDueCount: 0,
    todayClientDueCount: 0,
    masterRenewalCount:0,
  });
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [totalRows, setTotalRows] = React.useState(0);
  const _Id = localStorage.getItem("id");
  const _clientId = localStorage.getItem("clientId");

  const openErrorModal = (errorMessage, stackTrace, fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getQuery = (count) => {
    let whereConditions1 = "AND DATE(cert.nextDueDate) <= CURDATE() - INTERVAL 5 DAY";
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        Client: "cust.companyName",
        "Instrument Name": "ins.instrumentName",
        "Certificate Number": "cert.certificateNumber",
        "ULR Number": "cert.ULRNo",
      }[searchBy];
      whereConditions = `and ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " and ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` cert.nextDueDate between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (whereConditions == "") {
      whereConditions = whereConditions1 + whereConditions;
    }

    if (userType == "3") {
      whereConditions +=
        whereConditions.length > 0
          ? ` and (cert.clientId=${_clientId})`
          : `and (cert.clientId=${_clientId})`;
    }

    if (whereConditions == "") {
      whereConditions += ` and cert.status != -1`;
    } else {
      whereConditions += ` and cert.status != -1 `;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT cert.clientId, cust.companyName,cert.certificateNumber,cert.ULRNo, ins.instrumentName,replace(replace( cert.ranges , '#',''),'|',' to ') as ranges, replace( cert.lc, '#',' ') as lc, cert.DUCID,cert.make,cert.model, cert.calibrationDate, cert.nextDueDate,   cert.id, cert.status, cert.dcNumber, cert.identificationNo, cert.identificationNo,  cert.dateOfIssue, cert.srnNo FROM certificates AS cert LEFT JOIN clients AS cust ON cert.clientId = cust.id LEFT JOIN instruments AS ins ON cert.instrumentId = ins.id WHERE cert.nextDueDate IS NOT NULL   ${whereConditions}  order by cert.id DESC ${pagination_settings}`,
    };

    if (count)
      // data.query = `select count(*) as no_of_rows from certificates as cert left join clients as cust on cert.clientId = cust.id left join instruments as ins on cert.instrumentId = ins.id  ${whereConditions}`;
      data.query = `SELECT COUNT(*) AS no_of_rows FROM certificates AS cert LEFT JOIN clients AS cust ON cert.clientId = cust.id LEFT JOIN instruments AS ins ON cert.instrumentId = ins.id WHERE cert.nextDueDate IS NOT NULL   ${whereConditions}`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
        setCounts(prevCounts => ({
          ...prevCounts,
          overAllClientDueCount: res.data[0]?.no_of_rows || 0
        }));
        
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchCertificates() {
    getTotalRows();
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setCertificate([...res.data]);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
      });
  }
  // api calls
  function fetchCertificateReadings() {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `certificateStaticReadings`)
      .then(async (res) => {
        setCertReadings(res.data);
      })
      .catch((err) => {
        console.log("cert data fetching error: ", err);
      });
  }

  const initializeCertTable = () => {
    for (let i = 0; i < certificate.length; i++) {
      if (certificate[i].cert_dateOfIssue != null) {
        certificate[i]["isProgress"] = 90;
      } else if (
        certReadings.some((data) => data.certificateId == certificate[i].id)
      ) {
        certificate[i]["isProgress"] = 70;
      } else {
        certificate[i]["isProgress"] = 30;
      }
    }
    setFinalData(certificate);
  };

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  // console.log("counts",counts)

  const getClientCount = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(
        url +
          `clients/count?_where=(status,ne,-1)${
            userType == 3 ? `~and(id,eq,${_clientId})` : ""
          }`
      )
      .then((res) => {
        setClientCount(res.data[0].no_of_rows);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getClientCount", "Dashboard")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };

  const getCertificateCount = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(
        url +
          `certificates/count${
            userType == 3 ? `?_where=(clientId,eq,${_clientId})` : ""
          }`
      )
      .then((res) => {
        setCertificateCount(res.data[0].no_of_rows);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getCertificateCount", "Dashboard")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };

  useEffect(() => {
    getClientCount();
    getCertificateCount();
    getTotalRows();
    fetchCertificateReadings();
  }, []);

  useEffect(() => {
    fetchCertificates();
  }, [rowsPerPage, page]);

  useEffect(() => {
    initializeCertTable();
  }, [certificate]);

  /////////////////////////////DashboardData////////////////////////

  const fetchCount = async (
    table,
    dateColumn,
    condition,
  ) => {
    try {
      const url = `${BASE_URL}dynamic`;
      const query = `SELECT * FROM ${table} WHERE DATE(${dateColumn}) ${condition}`;
      if (userType == 3) {
        query += ` AND clientId = ${_clientId}`;
      }
      const response = await axiosWithToken.post(url, { query });
      return response.data.length;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

  const fetchDataCounts = async () => {
    const masterRenewalCount = await fetchCount(
      "standards",
      "validUpto",
      "<= CURDATE()",
    );    
    const todaysClientsCount = await fetchCount(
      "clients",
      "lastModified",
      "= CURDATE()"
    );
    const todaysSrfsCount = await fetchCount(
      "srfs",
      "lastModified",
      "= CURDATE()",
    );
    const todaysCertificatesCount = await fetchCount(
      "certificates",
      "lastModified",
      "= CURDATE()",
    );
    const todaysRenewalsCount = await fetchCount(
      "certificates",
      "nextDueDate",
      "= CURDATE()",
    );
    const overAllActiveIncCount = await fetchCount(
      "standards",
      "validUpto",
      "<= CURDATE()",
    );
    const overAllRenewalCount = await fetchCount(
      "certificates",
      "nextDueDate",
      ">= CURDATE()",
    );
    const todayClientDueCount = await fetchCount(
      "certificates",
      "nextDueDate",
      "= CURDATE()",
    );
    

    setCounts(prevCounts => ({
      ...prevCounts,
      todaysClientsCount,
      todaysSrfsCount,
      todaysCertificatesCount,
      todaysRenewalsCount,
      overAllActiveIncCount,
      overAllRenewalCount,
      todayClientDueCount,
      masterRenewalCount,
    }));
    
  };

  useEffect(() => {
    fetchDataCounts();
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {userType == 2 || userType == 5 || userType == 6 ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                height: "auto",
                maxHeight: "100%",
                maxWidth: "100%",
                width: "1250px",
                objectFit: "contain",
              }}
              src={Dashboard_background}
              alt="dashboard_background"
            />
          </div>
        ) : (
          <>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip title="User Guide" placement="top-start">
                <Button
                  onClick={() => {
                    dashboardGuides.drive();
                  }}
                >
                  <HelpIcon />
                </Button>
              </Tooltip>
            </div>
            <div component={Paper} sx={{ mt: 2 }}>
              <Divider sx={{ mt: 5 }}>
                <Chip
                  label="OVERALL"
                  variant="outlined"
                  sx={{ fontSize: "16px" }}
                  style={{ fontWeight: "bold" }}
                />
              </Divider>
              <Grid container spacing={3}>
                {userType != 3 && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CustomCard
                      icon={GroupIcon}
                      iconColor="#283593"
                      title="Clients"
                      count={clientCount}
                      link="/master/client"
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CustomCard
                    icon={CardGiftcardOutlinedIcon}
                    iconColor="#283593"
                    title="Certificates"
                    count={certificateCount}
                    link="/certificate"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CustomCard
                    icon={PrecisionManufacturingOutlinedIcon}
                    iconColor="#283593"
                    title="DUC Renewals"
                    count={counts.overAllRenewalCount}
                    link="/scheduler"
                  />
                </Grid>
                {userType != 3 && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CustomCard
                      icon={RotateLeftOutlinedIcon}
                      iconColor="#283593"
                      title="Master Renewals"
                      count={counts.masterRenewalCount}
                      link="/master/masterEQP"
                    />
                  </Grid>
                )}
                {userType == 3 && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CustomCard
                      icon={RotateLeftOutlinedIcon}
                      iconColor="#283593"
                      title="Dues"
                      count={counts.overAllClientDueCount}
                      link="/scheduler"
                    />
                  </Grid>
                )}
              </Grid>
            </div>
            <div component={Paper} sx={{ mt: 5 }}>
              <Divider sx={{ mt: 5 }}>
                <Chip
                  label="TODAY'S"
                  variant="outlined"
                  sx={{ fontSize: "1rem" }}
                  style={{ fontWeight: "bold" }}
                />
              </Divider>
              <Grid container spacing={3}>
                {userType != 3 && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CustomCard
                      icon={GroupIcon}
                      iconColor="#ff5722"
                      title="New clients"
                      count={counts.todaysClientsCount}
                      link="/master/client"
                    />
                  </Grid>
                )}
                {userType != 3 && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CustomCard
                      icon={ArticleIcon}
                      iconColor="#ff5722"
                      title="New SRF"
                      count={counts.todaysSrfsCount}
                      link="/srf"
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CustomCard
                    icon={CardGiftcardOutlinedIcon}
                    iconColor="#ff5722"
                    title="Certificates"
                    count={counts.todaysCertificatesCount}
                    link="/certificate"
                  />
                </Grid>
                {userType != 3 && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CustomCard
                      icon={RotateLeftOutlinedIcon}
                      iconColor="#ff5722"
                      title="DUC Renewals"
                      count={counts.todaysRenewalsCount}
                      link="/scheduler"
                    />
                  </Grid>
                )}
                {userType == 3 && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CustomCard
                      icon={RotateLeftOutlinedIcon}
                      iconColor="#ff5722"
                      title="Dues"
                      count={counts.todayClientDueCount}
                      link="/scheduler"
                    />
                  </Grid>
                )}
              </Grid>
            </div>

            {userType != 3 ? (
              <div component={Paper} sx={{ mt: 5 }}>
                <Divider sx={{ mt: 5 }}>
                  <Chip
                    label="ANALYTICS"
                    variant="outlined"
                    sx={{ fontSize: "16px" }}
                    style={{ fontWeight: "bold" }}
                  />
                </Divider>
                <Tabs
                  defaultActiveKey="profile"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="profile" title="Commercial Today's Report">
                    <PaymentReport />
                  </Tab>
                  <Tab eventKey="contact" title="Standard Out-in">
                    <StdInOut />
                  </Tab>
                </Tabs>
              </div>
            ) : (
              <div component={Paper} sx={{ mt: 5 }}>
                <Divider sx={{ mt: 5 }}>
                  <Chip
                    label="Scheduler"
                    variant="outlined"
                    sx={{ fontSize: "16px" }}
                    style={{ fontWeight: "bold" }}
                  />
                </Divider>
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    size="small"
                    id="datatable-keytable"
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            <b>{column.label}</b>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {finalData &&
                        finalData.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{row.companyName}</TableCell>
                              <TableCell>{row.certificateNumber}</TableCell>
                              <TableCell>{row.ULRNo}</TableCell>
                              <TableCell>{row.instrumentName}</TableCell>
                              <TableCell>
                                {row.ranges
                                  ?.replaceAll("||", ", ")
                                  ?.replaceAll("|", " to ")
                                  ?.replaceAll("#", "")}
                              </TableCell>
                              <TableCell>
                                {row.lc
                                  ?.replaceAll("||", ", ")
                                  ?.replaceAll("|", " to ")
                                  ?.replaceAll("#", "")}
                              </TableCell>
                              <TableCell>{row.DUCID}</TableCell>
                              <TableCell>{row.make}</TableCell>
                              <TableCell>{row.model}</TableCell>
                              <TableCell>
                                {moment(row.calibrationDate).format("DD-MM-YYYY")}
                              </TableCell>
                              <TableCell>
                                {moment(row.nextDueDate).format("DD-MM-YYYY")}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
                  count={totalRows}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  component="div"
                  colSpan={3}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  ActionsComponent={TablePaginationActions}
                />
              </div>
            )}
          </>
        )}

        {errormodalIsOpen && (
          <ErrorModal
            errormodalIsOpen={errormodalIsOpen}
            closeErrorModal={closeErrorModal}
            errorMessage={errorMessage}
          />
        )}
      </div>
    </>
  );
}
